<template>
  <div id="test8">
    <div class="crumbs">人事管理 / 人员管理</div>
    <div class="center">
      <div class="table">
        <div class="center_top">
          <span>
            <el-form :inline="true" class="demo-form-inline">
              <el-form-item label="员工岗位">
                <el-select v-model="staff_type" placeholder="全部">
                  <el-option
                    v-for="(item, index) in typearr"
                    :key="index"
                    :label="item.value"
                    :value="item.type"
                  >
                  </el-option>
                  <el-option :label="'未设置'" :value="0"> </el-option>
                  <el-option :label="'全部'" :value="null"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="姓名/手机号查询">
                <el-input
                  placeholder="请输入姓名/手机号"
                  v-model="keyword"
                ></el-input>
              </el-form-item>
              <el-form-item label="所属街镇">
                <el-select v-model="search_street_id" placeholder="请选择">
                  <el-option :label="'全部'" :value="''"> </el-option>
                  <el-option
                    v-for="streetItem in StreetList"
                    :label="streetItem.street_name"
                    :value="streetItem.street_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="medium" @click="getlist"
                  >查询</el-button
                >
              </el-form-item>
            </el-form>
          </span>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#F5F7FA', 'text-align': 'center' }"
        >
          <el-table-column
            prop="app_user_id"
            label="编号"
            width="50"
            :align="'center'"
          >
          </el-table-column>
          <el-table-column prop="app_user_name" label="姓名" :align="'center'">
          </el-table-column>
          <el-table-column prop="app_user_phone" label="电话" :align="'center'">
          </el-table-column>
          <el-table-column prop="user_type" label="岗位类型" :align="'center'">
            <template slot-scope="scope">
              <div>
                {{
                  scope.row.user_type == 0
                    ? "未设置用户职位"
                    : typearr[scope.row.user_type - 1].value
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="join_date" label="入职日期" :align="'center'">
          </el-table-column>
          <el-table-column
            prop="street_name"
            label="所属街道"
            :align="'center'"
          >
          </el-table-column>
          <el-table-column label="操作" :align="'center'" width="300">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="operateStaffDetailShowFn(scope.row)"
                >详情编辑</el-button
              >
              <el-button type="text" @click="lookdetails(scope.row)"
                >查看详情</el-button
              >
              <el-button type="text" @click="editpersonnel(scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <span style="float: right; margin-top: 20px; margin-right: 20px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            background
          >
          </el-pagination>
        </span>
      </div>
    </div>
    <el-dialog
      title="人员详情信息"
      :visible.sync="lookdetailsisShow"
      :before-close="handleClose"
    >
      <el-table
        :data="gridData"
        :header-cell-style="{ background: '#F5F7FA', 'text-align': 'center' }"
      >
        <el-table-column
          prop="identity_number"
          label="身份证号"
          :align="'center'"
        ></el-table-column>
        <el-table-column
          prop="register_address"
          label="户籍地址"
          :align="'center'"
        ></el-table-column>
        <el-table-column
          prop="now_address"
          label="现居住地"
          :align="'center'"
        ></el-table-column>
        <el-table-column
          prop="sex"
          label="性别"
          width="50"
          :align="'center'"
        ></el-table-column>
        <el-table-column label="照片" :align="'center'">
          <template slot-scope="scope">
            <img
              v-for="(item, index) in scope.row.images_arr"
              :key="index"
              :src="item"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column label="紧急联系人" :align="'center'">
          <template slot-scope="scope">
            <ul>
              <li>姓名:{{ scope.row.urgent_info.name }}</li>
              <li>手机:{{ scope.row.urgent_info.phone }}</li>
              <li>关系:{{ scope.row.urgent_info.relation }}</li>
            </ul>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
      title="编辑人员基本信息"
      :visible.sync="editpersonnelisShow"
      width="30%"
    >
      <el-form label-width="100px" :model="addpersonnel">
        <el-form-item label="姓名:" prop="staff_name">
          <el-input v-model="addpersonnel.app_user_name"></el-input>
        </el-form-item>
        <el-form-item label="电话:" prop="staff_phone">
          <el-input v-model="addpersonnel.app_user_phone"></el-input>
        </el-form-item>
        <el-form-item label="岗位类型:" prop="staff_type">
          <el-select v-model="addpersonnel.user_type" placeholder="请选择">
            <el-option
              v-for="(item, index) in typearr"
              :key="index"
              :label="item.value"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <AreaSelect ref="_Area" street_type="select" :areadata="areaData" />
        <el-form-item label="入职时间:" prop="staff_phone">
          <el-date-picker
            v-model="addpersonnel.join_date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editpersonnelisShow = false">取 消</el-button>
        <el-button type="primary" @click="setclickaddpersonnel"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="编辑人员详细信息"
      :visible.sync="operateStaffDetailShow"
      width="30%"
    >
      <el-form label-width="100px" :model="addpersonnel">
        <el-form-item label="身份证号码:" prop="staff_name">
          <el-input v-model="operateStaffDetailData.identity_number"></el-input>
        </el-form-item>
        <el-form-item label="户籍地址:" prop="staff_phone">
          <el-input
            v-model="operateStaffDetailData.register_address"
          ></el-input>
        </el-form-item>
        <el-form-item label="现居住地:" prop="staff_type">
          <el-input v-model="operateStaffDetailData.now_address"></el-input>
        </el-form-item>
        <el-form-item label="紧急联系人:" prop="staff_phone">
          <div style="display: flex">
            <div>
              姓名:
              <el-input
                v-model="operateStaffDetailData.urgent_info.name"
              ></el-input>
            </div>
            <div>
              手机号:
              <el-input
                v-model="operateStaffDetailData.urgent_info.phone"
              ></el-input>
            </div>
            <div>
              关系:
              <el-input
                v-model="operateStaffDetailData.urgent_info.relation"
              ></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="人员照片:" prop="staff_phone">
          <el-upload
            :action="'https://zhhw.fenfeneco.com/api/common/userUpload'"
            :on-success="upLoadsuccess"
            :file-list="fileList"
            list-type="picture"
            :headers="headers"
            :on-remove="upLoadremove"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="operateStaffDetailShow = false">取 消</el-button>
        <el-button type="primary" @click="operateStaffDetailFn"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  staffList,
  operateStaff,
  getGrantStreetList,
  operateStaffDetail,
  userUpload,
} from "../request/api";
import axios from "axios";
import AreaSelect from "../components/AreaSelect/AreaSelect.vue";
export default {
  name: "DemoTest17",
  components: {
    AreaSelect,
  },
  data() {
    return {
      staff_id: "",
      headers: {
        token: "",
      },
      fileList: [],
      operateStaffDetailShow: false, //详情编辑是否显示
      staff_type: null,
      keyword: "",
      page: 1,
      limit: 10,
      total: 0,
      tableData: [],
      lookdetailsisShow: false, //查看详情是否显示
      gridData: [],
      addpersonnel: {
        app_user_name: "",
        app_user_phone: null,
        street_id: null,
        user_type: null,
        join_date: "",
      },
      search_street_id: "",
      StreetList: [],
      typearr: [
        { value: "司机", type: 1 },
        { value: "环卫工人", type: 2 },
        { value: "区域管理员", type: 3 },
        { value: "经理", type: 4 },
      ],
      editpersonnelisShow: false, //编辑基本信息是否显示
      operateStaffDetailData: {
        //编辑详情数据
        identity_number: "",
        urgent_info: {
          name: "",
          phone: "",
          relation: "",
        },
        register_address: "",
        now_address: "",
      },
      images: [],
      areaData: {
        province: "",
        city: "",
        area: "",
        street: "",
        street_id: null,
      },
    };
  },

  async mounted() {
    this.headers.token = sessionStorage.getItem("token");
    this.getlist();
    let res1 = await getGrantStreetList();
    this.StreetList = res1.data.list;
  },

  methods: {
    //获取数据列表
    async getlist() {
      let res = await staffList({
        page: this.page, //当前页
        limit: this.limit, //每页多少条
        street_id: this.search_street_id, //街道id
        staff_type: this.staff_type, //岗位类型，环卫工/司机
        keywords: this.keyword, //搜索关键词。手机号，姓名
      });
      this.total = res.data.counts;
      this.tableData = res.data.list;
    },
    lookdetails(row) {
      this.lookdetailsisShow = true;
      if (JSON.stringify(row.staff_detail_info) != []) {
        this.gridData = [];
        this.gridData.push(row.staff_detail_info);
      } else {
        this.gridData = [];
      }
    },
    handleClose() {
      this.lookdetailsisShow = false;
      this.gridData = [];
    },
    //编辑人员基本信息
    editpersonnel(row) {
      this.editpersonnelisShow = true;
      let abc = row;
      if (abc.user_type == 0) {
        abc.user_type = "";
      }
      this.areaData.province = row.info.province_name;
      this.areaData.city = row.info.city_name;
      this.areaData.area = row.info.area_name;
      this.areaData.street = row.street_name || "";
      this.areaData.street_id = row.street_id || null;

      this.addpersonnel = abc;
    },
    handleSizeChange(val) {
      this.limit = val;
      this.getlist();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getlist();
    },
    async setclickaddpersonnel() {
      let _street_id = this.$refs._Area.areaIds.street_id;

      if (isNaN(_street_id)) {
        _street_id = this.areaData.street_id;
      }

      if (_street_id == "" || _street_id == null) {
        this.$message.error("请选择街道!");
        return;
      }

      this.addpersonnel.street_id = _street_id;

      let res = await operateStaff(this.addpersonnel);
      console.log(res);
      if (res.code == 1) {
        this.getlist();
        this.editpersonnelisShow = false;
        this.addpersonnel = {
          app_user_name: "",
          app_user_phone: null,
          street_id: null,
          user_type: null,
          join_date: "",
        };
      }
    },
    //点击详情编辑
    operateStaffDetailShowFn(row) {
      this.operateStaffDetailShow = true;
      let abc = row.staff_detail_info;
      this.staff_id = row.app_user_id;
      if (JSON.stringify(abc.staff_detail_info) != "[]") {
        // console.log(abc.urgent_info);
        // console.log(abc);
        if (!abc.urgent_info) {
          abc.urgent_info = {
            name: "",
            phone: "",
            relation: "",
          };
        }

        this.operateStaffDetailData = abc;
      }
      // console.log(this.operateStaffDetailData);
    },
    //确定编辑人员信息
    async operateStaffDetailFn() {
      // this.userUploadFn(this.fileList)
      this.operateStaffDetailShow = false;
      let res = await operateStaffDetail({
        staff_id: Number(this.staff_id),
        identity_number: this.operateStaffDetailData.identity_number,
        urgent_people: JSON.stringify(this.operateStaffDetailData.urgent_info),
        register_address: this.operateStaffDetailData.register_address,
        now_address: this.operateStaffDetailData.now_address,
        images: this.images.join(","),
      });
      this.images = [];
      this.fileList = [];
      this.getlist();
    },
    //上传成功的钩子
    upLoadsuccess(response, file, fileList) {
      this.images.push(response.data.oss_url);
    },
    //删除已上传图片的钩子
    upLoadremove(file, fileList) {
      console.log(fileList);
      this.images = [];
      fileList.forEach((item) => {
        this.images.push(item.response.data.oss_url);
      });
    },
  },
};
</script>

<style lang="less" scoped>
#test8 {
  width: 100%;
  height: 100vh;
}

.crumbs {
  padding: 5px 20px;
  background-color: rgb(240, 240, 240);
}

.center {
  width: 100vw;
  height: calc(100% - 31px);
  // background-color: #000;
  margin: auto;

  .center_top {
    padding-top: 10px;
    padding-bottom: 13px;
    height: 36px;
    display: flex;
    justify-content: center;

    ::v-deep .el-input__inner {
      width: 190px;
      height: 32px;
    }
  }

  .table {
    height: calc(100vh - 100px);
    overflow: auto;
    padding: 0 10px;
  }
}
</style>
